import React from 'react';
import { AppBar, Toolbar, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';

const NavigationBar = () => {
  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ mr: 4 }}>
          IoT Dashboard
        </Typography>
        {/* <Button color="inherit" component={Link} to="/login">Login</Button>
        <Button color="inherit" component={Link} to="/register">Register</Button> */}
        <Button color="inherit" component={Link} to="/dashboard">Dashboard</Button>
        {/* <Button color="inherit" component={Link} to="/devices">Devices</Button>
        <Button color="inherit" component={Link} to="/home-assistant">Home Assistant</Button> */}
        <Button color="inherit" component={Link} to="/mqtt/client">MQTT Client</Button>
        <Button color="inherit" component={Link} to="/iot-device-definition">Define IoT Device</Button>
        <Button color="inherit" component={Link} to="/mask">The Mask</Button>
        {/* <Button color="inherit" component={Link} to="/mqtt/subscribe">MQTT Subscribe</Button>
        <Button color="inherit" component={Link} to="/mqtt/publish">MQTT Publish</Button> */}
        {/* <Button color="inherit" component={Link} to="/notifications/alerts">Notifications Alerts</Button>
        <Button color="inherit" component={Link} to="/settings/user-profile">User Profile</Button>
        <Button color="inherit" component={Link} to="/websocket/real-time-updates">WebSocket Real-Time Updates</Button> */}
      </Toolbar>
    </AppBar>
  );
};

export default NavigationBar;