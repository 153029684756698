import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NavigationBar from './components/NavigationBar';
import Login from './components/Auth/Login';
import Register from './components/Auth/Register';
import Overview from './components/Dashboard/Overview';
import DeviceList from './components/Devices/DeviceList';
import EntityList from './components/HomeAssistant/EntityList';
// import Subscribe from './components/MQTT/Subscribe';
// import Publish from './components/MQTT/Publish';
import Alerts from './components/Notifications/Alerts';
import UserProfile from './components/Settings/UserProfile';
import RealTimeUpdates from './components/WebSocket/RealTimeUpdates';
import MqttClient from './components/MQTT/MqttClient';
import IoTDeviceDefinition from './components/Devices/IoTDeviceDefinition';
import Face from './components/Mediapipe/Face';

const App = () => {
  return (
    <Router>
      <NavigationBar />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/dashboard" element={<Overview />} />
        <Route path="/devices" element={<DeviceList />} />
        <Route path="/iot-device-definition" element={<IoTDeviceDefinition />} />
        <Route path="/mask" element={<Face />} />
        <Route path="/home-assistant" element={<EntityList />} />
        <Route path="/mqtt/client" element={<MqttClient />} />
        <Route path="/notifications/alerts" element={<Alerts />} />
        <Route path="/settings/user-profile" element={<UserProfile />} />
        <Route path="/websocket/real-time-updates" element={<RealTimeUpdates />} />
        {/* <Route path="/mqtt/subscribe" element={
          <Subscribe
            options={{
              host: 'localhost',
              port: 9001,
              protocol: 'ws',
              clientId: 'iot01_frontend_Sub',
              clean: true,
              keepalive: 55000,
              reconnectPeriod: 60000,
            }}
            topic="lala"
          />
        } />
        <Route path="/mqtt/publish" element={
          <Publish
            options={{
              host: 'localhost',
              port: 9001,
              protocol: 'ws',
              clientId: 'iot01_frontend_Pub',
              clean: true,
              keepalive: 55000,
              reconnectPeriod: 60000,
            }}
            topic="lala"
          />
        } /> */}
      </Routes>
    </Router>
  );
};

export default App;
/*
        <Route path="/mqtt/subscribe" element={
          <Subscribe
            // brokerUrl="localhost"
            options={{
              host: 'localhost',
              // port: 1883,
              // protocol: 'mqtt',
              port: 9001,
              protocol: 'ws',
              clientId: 'mqttjs_lalaSub',
              clean: true, // set to false to receive QoS 1 and 2 messages while offline (default true)
              keepalive: 55000,
              reconnectPeriod: 60000,
            }}
            topic="lala"
          />
        } />
        <Route path="/mqtt/publish" element={
          <Publish
            // brokerUrl="localhost"
            // options={{
            options={{
              host: 'localhost',
              // port: 1883,
              // protocol: 'mqtt',
              port: 9001,
              protocol: 'ws',
              clientId: 'mqttjs_lalaPub',
              // clientId: 'mqttjs_' + Math.random().toString(16).substr(2, 8),
              clean: true, // set to false to receive QoS 1 and 2 messages while offline (default true)
              keepalive: 55000,
              reconnectPeriod: 60000,
            }}
            topic="lala"
          />
        } />

import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';

function App() {
  const [ws, setWs] = useState(null);
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    // Create WebSocket connection
    const socket = new WebSocket('ws://localhost:5000');

    // Connection opened
    socket.addEventListener('open', () => {
      console.log('WebSocket connection established');
      setWs(socket);
    });

    // Listen for messages
    socket.addEventListener('message', event => {
      console.log('Message from server:', event.data);
      setMessages(prevMessages => [...prevMessages, event.data]);
    });

    // Clean up the WebSocket connection when the component unmounts
    return () => {
      socket.close();
    };
  }, []);

  const sendMessage = () => {
    if (ws) {
      ws.send('Hello from frontend!');
    }
  };

  return (
    <div>
      <h1>Hello from frontend!</h1>
      <Button variant="contained" onClick={sendMessage}>Send WebSocket Message</Button>
      <div>
        <h2>Messages:</h2>
        <ul>
          {messages.map((msg, index) => (
            <li key={index}>{msg}</li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default App;
*/