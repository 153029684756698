import React, { useEffect, useRef } from 'react';
import { Box, Button } from '@mui/material';

import vision from "https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.3";

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  '@global': {
    // body: {
    //   fontFamily: 'helvetica, arial, sans-serif',
    //   margin: '2em',
    //   color: '#3d3d3d',
    //   '--mdc-theme-primary': '#007f8b',
    //   '--mdc-theme-on-primary': '#f1f3f4',
    // },
    h1: {
      margin: '4px 0 4px 0',
      fontStyle: 'italic',
      color: '#007f8b',
    },
    h2: {
      clear: 'both',
    },
    em: {
      fontWeight: 'bold',
    },
    video: {
      clear: 'both',
      display: 'none', // Hide the video element
      transform: 'rotateY(180deg)',
      WebkitTransform: 'rotateY(180deg)',
      MozTransform: 'rotateY(180deg)',
      // width
    },
    section: {
      opacity: 1,
      transition: 'opacity 500ms ease-in-out',
    },
    header: {
      clear: 'both',
    },
    footer: {
      clear: 'both',
    },
    // '.removed': {
    //   display: 'none',
    // },
    '.invisible': {
      opacity: 0.2,
    },
    '.note': {
      fontStyle: 'italic',
      fontSize: '130%',
    },
    '.canvas': {
      zIndex: 1,
      position: 'absolute',
      pointerEvents: 'none',
    },
    // '.output_canvas': {
    //   backgroundColor: 'black',
    //   transform: 'rotateY(180deg)',
    //   WebkitTransform: 'rotateY(180deg)',
    //   MozTransform: 'rotateY(180deg)',
    // },
    '.blend-shapes-item': {
      maxWidth: '240px',
      minWidth: '240px',
      display: 'flex',
      alignItems: 'center',
      height: '20px',
    },
    '.blend-shapes-label': {
      display: 'flex',
      maxWidth: '140px',
      minWidth: '140px',
      justifyContent: 'flex-end',
      alignItems: 'center',
      marginRight: '4px',
    },
    '.blend-shapes-value': {
      display: 'flex',
      height: '16px',
      alignItems: 'center',
      backgroundColor: '#007f8b',
    },
  },
  output_canvas: {
    width: '800px',
    height: '600px',
    backgroundColor: 'black',
    transform: 'rotateY(180deg)',
    WebkitTransform: 'rotateY(180deg)',
    MozTransform: 'rotateY(180deg)',
  },
  videoView: {
    position: 'relative',
    float: 'left',
    width: '48%',
    margin: '2% 1%',
    cursor: 'pointer',
  },
  videoViewP: {
    position: 'absolute',
    padding: '5px',
    backgroundColor: '#007f8b',
    color: '#fff',
    border: '1px dashed rgba(255, 255, 255, 0.7)',
    zIndex: 2,
    fontSize: '12px',
    margin: 0,
  },
  blendShapes: {
    padding: '0px',
    margin: '0px',
    // width: '140px',
    // position: 'relative',
    // float: 'left',
    // width: '48%',
    // margin: '2% 1%',
    // cursor: 'pointer',
  },
});

const Face = () => {
  const classes = useStyles();

  const { FaceLandmarker, FilesetResolver, DrawingUtils } = vision;

  useEffect(() => {

    // If webcam supported, add event listener to button for when user
    // wants to activate it.
    if (hasGetUserMedia()) {
      enableWebcamButton = webcamButtonRef.current;
      if (enableWebcamButton) {
        enableWebcamButton?.addEventListener("click", enableCam);
      }
    } else {
      console.warn("getUserMedia() is not supported by your browser");
    }
  
  }, []);

  const videoBlendShapesRef = useRef(null);

  // const videoBlendShapes = videoBlendShapesRef.current;

  let faceLandmarker;
  let runningMode = "IMAGE";
  let enableWebcamButton;
  let webcamRunning = false;
  const videoWidth = 800;

  // Before we can use HandLandmarker class we must wait for it to finish
  // loading. Machine Learning models can be large and take a moment to
  // get everything needed to run.
  async function createFaceLandmarker() {
    const filesetResolver = await FilesetResolver.forVisionTasks(
      "https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.3/wasm"
    );
    faceLandmarker = await FaceLandmarker.createFromOptions(filesetResolver, {
      baseOptions: {
        modelAssetPath: `https://storage.googleapis.com/mediapipe-models/face_landmarker/face_landmarker/float16/1/face_landmarker.task`,
        delegate: "GPU"
      },
      outputFaceBlendshapes: true,
      runningMode,
      numFaces: 1
    });
  }
  createFaceLandmarker();


  /********************************************************************
  // Demo 2: Continuously grab image from webcam stream and detect it.
  ********************************************************************/
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const webcamButtonRef = useRef(null);

  // const video = videoRef.current;
  // const canvasElement = canvasRef.current;

  // const canvasCtx = canvasElement?.getContext("2d");

  // Check if webcam access is supported.
  function hasGetUserMedia() {
    return !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia);
  }

  // // If webcam supported, add event listener to button for when user
  // // wants to activate it.
  // if (hasGetUserMedia()) {
  //   enableWebcamButton = webcamButtonRef.current;
  //   if (enableWebcamButton) {
  //     enableWebcamButton?.addEventListener("click", enableCam);
  //   }
  // } else {
  //   console.warn("getUserMedia() is not supported by your browser");
  // }

  // Enable the live webcam view and start detection.
  function enableCam(event) {
    const video = videoRef.current;
    if (!faceLandmarker) {
      console.log("Wait! faceLandmarker not loaded yet.");
      return;
    }

    if (webcamRunning === true) {
      webcamRunning = false;
      enableWebcamButton.innerText = "ENABLE PREDICTIONS";
    } else {
      webcamRunning = true;
      enableWebcamButton.innerText = "DISABLE PREDICTIONS";
    }

    // getUsermedia parameters.
    const constraints = {
      video: true
    };

    // Activate the webcam stream.
    navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
      video.srcObject = stream;
      video.addEventListener("loadeddata", predictWebcam);
    });
  }

  let circleX, circleY;
  const circleRadius = 40; // Adjust the size as needed

  function drawCircle(ctx, x, y, radius) {
    ctx.beginPath();
    ctx.arc(x, y, radius, 0, 2 * Math.PI, false);
    ctx.fillStyle = 'blue';
    ctx.fill();
  }

  function drawFaceMesh(ctx, landmarks) {


    // Draw the blue circle above the face
    // const foreheadIndex = 10; // Index of a landmark near the forehead
    // const foreheadX = landmarks[foreheadIndex].x * ctx.canvas.width;
    // const foreheadY = landmarks[foreheadIndex].y * ctx.canvas.height;
    // const circleRadius = 40; // Adjust the size as needed
    // drawCircle(ctx, foreheadX, foreheadY - 80, circleRadius); // Adjust the position as needed
    drawCircle(ctx, circleX, circleY, circleRadius);
  }

  // Initialize the circle's position above the face
  function initializeCirclePosition(ctx, landmarks) {
    const foreheadIndex = 10; // Index of a landmark near the forehead
    if (landmarks?.length > foreheadIndex) {
      circleX = landmarks[foreheadIndex].x * ctx.canvas.width;
      circleY = landmarks[foreheadIndex].y * ctx.canvas.height - 80; // Adjust the position as needed
      console.log('initializeCirclePosition');
      console.log(circleX, circleY);
    }
  }

  function updateCirclePosition(ctx, blendShapes) {
    const confidenceThreshold = 0.3;
    const invertHorizontally = process.env.REACT_APP_INVERT_EYE_LEFT_RIGHT ? 1 : -1;
    let step;
    blendShapes.forEach((shape) => {
        if (shape.categoryName === 'eyeLookInRight' && shape.score > confidenceThreshold) {
          step = shape.score * 10 * invertHorizontally;
          if (circleX + step + circleRadius <= ctx.canvas.width) {
              circleX += step;
          }
        }
        if (shape.categoryName === 'eyeLookOutRight' && shape.score > confidenceThreshold) {
          step = shape.score * 10 * invertHorizontally;
          if (circleX - step - circleRadius >= 0) {
              circleX -= step;
          }
        }
        if (shape.categoryName === 'eyeLookUpRight' && shape.score > confidenceThreshold/2) {
          step = shape.score * 10;
          if (circleY - step*2 - circleRadius >= 0) {
              circleY -= step*2;
          }
        }
        if (shape.categoryName === 'eyeLookDownRight' && shape.score > confidenceThreshold/2) {
          step = shape.score * 10;
          if (circleY + step*2 + circleRadius <= ctx.canvas.height) {
              circleY += step*2;
          }
        }
    });
    console.log(circleX, circleY);
  }

  let lastVideoTime = -1;
  let results = undefined;
  async function predictWebcam() {
    const video = videoRef.current;
    const canvasElement = canvasRef.current;
    const videoBlendShapes = videoBlendShapesRef.current;

    const canvasCtx = canvasElement?.getContext("2d");
    const drawingUtils = new DrawingUtils(canvasCtx);

    const radio = video.videoHeight / video.videoWidth;
    video.style.width = videoWidth + "px";
    video.style.height = videoWidth * radio + "px";
    canvasElement.style.width = videoWidth + "px";
    canvasElement.style.height = videoWidth * radio + "px";
    canvasElement.width = video.videoWidth;
    canvasElement.height = video.videoHeight;
    // Now let's start detecting the stream.
    if (runningMode === "IMAGE") {
      runningMode = "VIDEO";
      await faceLandmarker.setOptions({ runningMode: runningMode });
    }
    let startTimeMs = performance.now();
    if (lastVideoTime !== video.currentTime) {
      lastVideoTime = video.currentTime;
      results = faceLandmarker.detectForVideo(video, startTimeMs);
    }
    if (results.faceLandmarks) {

    // Initialize the circle's position
      if (circleX === undefined || circleY === undefined) {
        initializeCirclePosition(canvasCtx, results.faceLandmarks[0]);
      }

      drawFaceMesh(canvasCtx, results.faceLandmarks[0]);
      if (results.faceBlendshapes?.length) {
        updateCirclePosition(canvasCtx, results.faceBlendshapes[0].categories);
      }
    }
    if (results.faceLandmarks) {
      // drawFaceMesh(canvasCtx, results.faceLandmarks[0]);
      for (const landmarks of results.faceLandmarks) {

        drawingUtils.drawConnectors(
          landmarks,
          FaceLandmarker.FACE_LANDMARKS_TESSELATION,
          { color: "#C0C0C070", lineWidth: 1 }
        );
        drawingUtils.drawConnectors(
          landmarks,
          FaceLandmarker.FACE_LANDMARKS_RIGHT_EYE,
          { color: "#FF3030" }
        );
        drawingUtils.drawConnectors(
          landmarks,
          FaceLandmarker.FACE_LANDMARKS_RIGHT_EYEBROW,
          { color: "#FF3030" }
        );
        drawingUtils.drawConnectors(
          landmarks,
          FaceLandmarker.FACE_LANDMARKS_LEFT_EYE,
          { color: "#30FF30" }
        );
        drawingUtils.drawConnectors(
          landmarks,
          FaceLandmarker.FACE_LANDMARKS_LEFT_EYEBROW,
          { color: "#30FF30" }
        );
        drawingUtils.drawConnectors(
          landmarks,
          FaceLandmarker.FACE_LANDMARKS_FACE_OVAL,
          { color: "#E0E0E0" }
        );
        drawingUtils.drawConnectors(
          landmarks,
          FaceLandmarker.FACE_LANDMARKS_LIPS,
          { color: "#3030FF" }
        );
        drawingUtils.drawConnectors(
          landmarks,
          FaceLandmarker.FACE_LANDMARKS_RIGHT_IRIS,
          { color: "#30FF30" }
        );
        drawingUtils.drawConnectors(
          landmarks,
          FaceLandmarker.FACE_LANDMARKS_LEFT_IRIS,
          { color: "#FF3030" }
        );
      }
    }
    drawBlendShapes(videoBlendShapes, results.faceBlendshapes);

    // Call this function again to keep predicting when the browser is ready.
    if (webcamRunning === true) {
      window.requestAnimationFrame(predictWebcam);
    }
  }

  function drawBlendShapes(el, blendShapes) {
    if (!blendShapes.length) {
      return;
    }

    let categoriesOfInterest = [
      // 'eyeLookDownLeft',
      'eyeLookDownRight',
      // 'eyeLookInLeft',
      'eyeLookInRight',
      // 'eyeLookOutLeft',
      'eyeLookOutRight',
      // 'eyeLookUpLeft',
      'eyeLookUpRight',
      // 'eyeBlinkLeft',
      'eyeBlinkRight',
    ]
    let htmlMaker = "";
    blendShapes[0].categories
    .filter((category) => categoriesOfInterest.includes(category.categoryName))
    .map((shape) => {
      htmlMaker += `
        <li class="blend-shapes-item">
          <span class="blend-shapes-label">${shape.displayName || shape.categoryName}</span>
          <span class="blend-shapes-value" style="width: ${+shape.score * 100}px">${(+shape.score).toFixed(4)}</span>
        </li>
      `;
    });

    categoriesOfInterest = [
      'eyeLookDownLeft',
      'eyeLookInLeft',
      'eyeLookOutLeft',
      'eyeLookUpLeft',
      'eyeBlinkLeft',
    ]
    blendShapes[0].categories
    .filter((category) => categoriesOfInterest.includes(category.categoryName))
    .map((shape) => {
      htmlMaker += `
        <li class="blend-shapes-item">
          <span class="blend-shapes-label">${shape.displayName || shape.categoryName}</span>
          <span class="blend-shapes-value" style="width: ${+shape.score * 100}px">${(+shape.score).toFixed(4)}</span>
        </li>
      `;
    });

    el.innerHTML = htmlMaker;
  }

  return (
    <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'space-arround'} >
      <h1>The Mask</h1>
      <Box display={'flex'} alignItems={'center'} justifyContent={'space-arround'} >
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'space-arround'} >
          <Button ref={webcamButtonRef} variant="contained" color={'primary'}>Enable Webcam</Button>
          <p>The blue ball follows your right eye</p>
        </Box>
        <Box display={'flex'}>
          <ul ref={videoBlendShapesRef} className={classes.blendShapes} id="videoBlendShapes"></ul>
        </Box>
      </Box>
      <Box display={'flex'} alignItems={'center'} justifyContent={'space-arround'} >
        <video ref={videoRef} id="webcam" autoPlay playsInline></video>
        <canvas ref={canvasRef} className={classes.output_canvas} id="output_canvas"></canvas>
      </Box>
    </Box>
  );
};

export default Face;
