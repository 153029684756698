import React, { useState, useEffect } from 'react';
import { Container, Typography, TextField, Button, List, ListItem, ListItemText, Divider, Box, Autocomplete, TextareaAutosize } from '@mui/material';
import { format } from 'date-fns';
import backendApi from '../../services/backendApi';
import { registerListener, unregisterListener } from '../../services/websocket';

const MqttClient = () => {
  const [subscribedTopics, setSubscribedTopics] = useState([]);
  const [publishedMessages, setPublishedMessages] = useState([]);
  const [receivedMessages, setReceivedMessages] = useState([]);
  const [newTopic, setNewTopic] = useState('');
  const [publishTopic, setPublishTopic] = useState('');
  const [publishMessage, setPublishMessage] = useState('');

  useEffect(() => {
    fetchSubscribedTopics();
    fetchPublishedMessages();
    fetchReceivedMessages();

    const handleMqttMessage = (message) => {
      setReceivedMessages((prevReceivedMessages) => prevReceivedMessages.toSpliced(0, 0, message));
    };

    registerListener('mqtt-message', handleMqttMessage);

    return () => {
      unregisterListener('mqtt-message', handleMqttMessage);
    };
  }, []);

  const fetchSubscribedTopics = async () => {
    try {
      const response = await backendApi.get('/mqtt/subscribed-topics');
      setSubscribedTopics(response.data);
    } catch (error) {
      console.error('Error fetching subscribed topics:', error);
    }
  };

  const fetchPublishedMessages = async () => {
    try {
      const response = await backendApi.get('/mqtt/published-messages');
      setPublishedMessages(response.data);
    } catch (error) {
      console.error('Error fetching published messages:', error);
    }
  };

  const fetchReceivedMessages = async () => {
    try {
      const response = await backendApi.get('/mqtt/received-messages');
      setReceivedMessages(response.data);
    } catch (error) {
      console.error('Error fetching received messages:', error);
    }
  };

  const handleSubscribe = async () => {
    try {
      await backendApi.post('/mqtt/subscribe', { topic: newTopic });
      setNewTopic('');
      fetchSubscribedTopics();
    } catch (error) {
      console.error('Error subscribing to topic:', error);
    }
  };

  const handlePublish = async () => {
    try {
      await backendApi.post('/mqtt/publish', { topic: publishTopic, message: publishMessage });
      // setPublishTopic('');
      // setPublishMessage('');
      fetchPublishedMessages();
    } catch (error) {
      console.error('Error publishing message:', error);
    }
  };

  const parseMessage = (message) => {
    let parsed = message;
    try {
      let json = JSON.parse(message);
      parsed = JSON.stringify(json, null, 2);
    } catch (e) {
      // console.error('Error parsing JSON:', e);
    }
    return parsed;
  }

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        MQTT Client 1.1.5
      </Typography>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Box flex={1} mr={2}>
          <Typography variant="h6">Messages (Received - Published)</Typography>
          <Box height="600px" overflow="auto" border={1} borderColor="grey.300" borderRadius={1} p={1}>
            <List dense>
              {receivedMessages.map((msg) => ({...msg, type: 'received'}))
              .concat(publishedMessages.map((msg) => ({...msg, type: 'published'})))
              .sort((a, b) => b.timestamp - a.timestamp)
              .map((msg, index) => (
                <ListItem key={index}
                  style={{justifyContent: msg.type === 'received' ? 'flex-start' : 'flex-end'}}
                >
                  <ListItemText
                    primary={`Topic: ${msg.topic}\n${parseMessage(msg.message)}`}
                    secondary={`${format(new Date(msg.timestamp), 'yyyy-MM-dd HH:mm:ss.SSS')}`}
                    // align={msg.type === 'received' ? 'left' : 'right'}
                    style={{
                      flexGrow: 'unset',
                      flexShrink: 'unset',
                      maxWidth: '80%',
                      padding: 8,
                      borderRadius: 8,
                      backgroundColor: msg.type === 'received' ? 'lightgrey' : 'lightblue',
                      whiteSpace: 'pre-wrap',
                      lineBreak: 'anywhere',
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        </Box>
        <Box flex={1} ml={2}>
          <Typography variant="h6" gutterBottom>
            Subscribed Topics
          </Typography>
          <Box height="100px" overflow="auto" border={1} borderColor="grey.300" borderRadius={1} p={1}>
            <List dense>
              {subscribedTopics.map((topic, index) => (
                <ListItem key={index}>
                  <ListItemText primary={topic} />
                </ListItem>
              ))}
            </List>
          </Box>
          <Divider />
          <Typography variant="h6">Subscribe to Topic</Typography>
          <TextField
            label="New Topic"
            value={newTopic}
            onChange={(e) => setNewTopic(e.target.value)}
            fullWidth
            margin="normal"
          />
          <Button variant="contained" color="primary" onClick={handleSubscribe} fullWidth>
            Subscribe
          </Button>
          <Divider sx={{ my: 2 }} />
          <Typography variant="h6">Publish Message</Typography>
          <Autocomplete
            options={subscribedTopics}
            value={publishTopic}
            onChange={(event, newValue) => setPublishTopic(newValue)}
            renderInput={(params) => <TextField {...params} label="Publish Topic" margin="normal" />}
            fullWidth
          />
          <TextareaAutosize
            minRows={4}
            placeholder="Message"
            value={publishMessage}
            onChange={(e) => setPublishMessage(e.target.value)}
            style={{ width: '100%', marginTop: '16px', padding: '8px', borderRadius: '4px', borderColor: 'grey.300' }}
          />
          <Button variant="contained" color="primary" fullWidth sx={{ mt: 2 }}
            disabled={!publishTopic || !publishMessage}
            onClick={handlePublish}
          >
            Publish
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default MqttClient;