import io from 'socket.io-client';

let backendUrl = `${window.location.origin}`; // Ensure this path matches the server configuration
console.log('Connecting to backend at:', backendUrl);

const socket = io(backendUrl, {
  transports: ['websocket'], //important (or else defaults to http-polling, which is blocked by cors)
  // query: { user: `${profile?.auth0User.user_id}` }, //custom query (payload) that will be sent to the server upon connection
});

const eventListeners = {}

socket.on('connect', () => {
  console.log(`socket connected: ${socket.id}`)
  // console.log(socket)
})

socket.on('disconnect', () => {
  console.log(`socket disconnected: ${socket.id}`)
})

socket.on('connect_error', err => {
  console.log(`socket connect_error due to ${err.message}`)
  console.error(err);
})

socket.on('message', (data) => {
  genericMessageHandler(data);
  if (data.event) {
    if (eventListeners[data.event]) {
      eventListeners[data.event].forEach((listener) => listener(data));
    } else {
      console.warn(`No registered listeners found for event ${data.event}`);
    }
  }
});

const genericMessageHandler = (data) => {
  console.log('genericMessageHandler: Received message:', data);
};

const registerListener = (event, listener) => {
  console.log(`Registering listener ${listener.name} for event:`, event);
  if (eventListeners[event]) {
    if (eventListeners[event].includes(listener)) {
      console.warn(`Listener ${listener.name} already registered for event ${event}`);
      return;
    }
    eventListeners[event].push(listener);
  } else {
    eventListeners[event] = [listener];
  }
};

const unregisterListener = (event, listener) => {
  console.log(`Unegistering listener ${listener.name} from event:`, event);
  if (eventListeners[event]) {
    eventListeners[event] = eventListeners[event].filter((l) => l !== listener);
  } else {
    console.warn(`No listeners found for event ${event}`);
  }
};

export { registerListener, unregisterListener };