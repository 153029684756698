import React, { useState, useRef, useEffect } from 'react';
import Webcam from 'react-webcam';
import axios from 'axios';
import { Box, Button, Typography } from '@mui/material';
// import backendApi from '../../services/backendApi';
import { registerListener, unregisterListener } from '../../services/websocket';

const Overview = () => {
  const webcamRef = useRef(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [error, setError] = useState(null);
  const [resultsImage, setResultsImage] = useState(null);

  const capture = () => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      if (imageSrc) {
        setImageSrc(imageSrc);
        setUploadedImage(null);
        setError(null);
      } else {
        setError('Failed to capture image. Please try again.');
      }
    } else {
      setError('Webcam not initialized.');
    }
  };

  const handleUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setUploadedImage(reader.result);
        setImageSrc(null);
        setError(null);
      };
      reader.readAsDataURL(file);
    }
  };

  const submitImage = async () => {
    const base64Image = imageSrc ? imageSrc.split(',')[1] : uploadedImage.split(',')[1];
    if (!base64Image) {
      setError('No image to submit.');
      return;
    }

    try {
      await axios.post('/api/camera/submit-image', { image: base64Image });
      setError(null);
      console.log('Image submitted successfully');
    } catch (err) {
      console.error('Error submitting image:', err);
      setError('Error submitting image. Please try again.');
    }
  };

  const human_detection_topic = 'homeassistant/camera/iot_models_human_detection/image'

  useEffect(() => {
    // backendApi.post('/mqtt/subscribe', { topic: human_detection_topic })
    // .catch((err) => {
    //   console.error('Error subscribing to topic:', err);
    // });

    const handleWebSocketMessage = (message) => {
      if (message.topic === human_detection_topic) {
        setResultsImage(message.message);
      }
    };

    registerListener('mqtt-message', handleWebSocketMessage);
    return () => {
      unregisterListener('mqtt-message', handleWebSocketMessage);
    };
  }, []);

  return (
    <Box p={2} display="flex" flexWrap={'wrap'} alignItems="center">
      <Box p={2} m={1} height={360}
        display="flex"
        flexDirection="column"
        flexGrow={1}
        justifyContent={'space-between'}
        alignItems="center"
        borderRadius={'8px'}
        backgroundColor="grey.100"
      >
        <Box display="flex" alignItems="center" p={2}>
          <Button variant="contained" color="primary" onClick={capture} style={{marginRight: '8px'}}>
            Capture
          </Button>
          <Typography variant="h6"> a snapshot</Typography>
        </Box>
        <Webcam
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          width={320}
          height={'auto'}
          //height={240}
          onUserMediaError={(err) => setError(`Webcam error: ${err.message}`)}
        />
      </Box>

      <Box p={2} m={1} height={360}
        display="flex"
        flexDirection="column"
        flexGrow={1}
        justifyContent={'space-between'}
        alignItems="center"
        borderRadius={'8px'}
        backgroundColor="grey.100"
      >
        <Box display="flex" alignItems="center" p={2}>
          <Typography variant="h6">.. or</Typography>
          <Button variant="contained" component="label" color="primary" style={{marginRight: '8px', marginLeft: '8px'}}>
            Upload
            <input type="file" hidden onChange={handleUpload} />
          </Button>
          <Typography variant="h6">an image</Typography>
        </Box>
        <Typography variant="caption">
          {imageSrc ? 'Captured image' : uploadedImage ? 'Uploaded image' : ''}
        </Typography>
        <Box>
          {imageSrc && 
          <img
            src={imageSrc}
            alt="Captured"
            width={320}
            height={'auto'}
          />
          }
          {uploadedImage &&
          <img
            src={uploadedImage}
            alt="Uploaded"
            width={320}
            height={'auto'}
            //height={240}
          />
          }
        </Box>
      </Box>

      <Box p={2} m={1} height={360}
        display="flex"
        flexDirection="column"
        flexGrow={1}
        justifyContent={'space-between'}
        alignItems="center"
        borderRadius={'8px'}
        backgroundColor="grey.100"
      >
        <Box display="flex" alignItems="center" p={2}>
          <Typography variant="h6">.. and</Typography>
          <Button variant="contained" color="primary" onClick={submitImage} style={{marginLeft: '8px'}}>
            Submit
          </Button>
        </Box>
        <Typography variant="caption">Results</Typography>
        <Box>
          {resultsImage &&
          <img
            src={`data:image/jpeg;base64,${resultsImage}`}
            alt="Results"
            width={320}
            height={'auto'}
            //height={240}
          />
          }
        </Box>
      </Box>

      {error && <Typography color="error">{error}</Typography>}
    </Box>
  );
};

export default Overview;
