import React, { useState } from 'react';
import { Box, TextField, Button, Typography, Container, Grid2, Paper, IconButton, Autocomplete } from '@mui/material';
import { Add as AddIcon, Remove as RemoveIcon } from '@mui/icons-material';

const IoTDeviceDefinition = () => {
  const [device, setDevice] = useState({
    name: '',
    description: '',
    sdfProperty: [],
    sdfAction: [],
    sdfEvent: []
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDevice({ ...device, [name]: value });
  };

  const handleAddField = (field) => {
    setDevice({ ...device, [field]: [...device[field], { name: '', description: '', type: '' }] });
  };

  const handleRemoveField = (field, index) => {
    const newFields = device[field].filter((_, i) => i !== index);
    setDevice({ ...device, [field]: newFields });
  };

  const handleFieldChange = (field, index, e) => {
    const { name, value } = e.target;
    const newFields = device[field].map((item, i) => (i === index ? { ...item, [name]: value } : item));
    setDevice({ ...device, [field]: newFields });
  };

  const handleAutocompleteChange = (field, index, value) => {
    const newFields = device[field].map((item, i) => (i === index ? { ...item, type: value } : item));
    setDevice({ ...device, [field]: newFields });
  };

  const handleSubmit = () => {
    const deviceDefinition = {
      info: {
        title: device.name,
        description: device.description
      },
      sdfObject: {
        [device.name]: {
          description: device.description,
          sdfProperty: device.sdfProperty.reduce((acc, prop) => {
            acc[prop.name] = { description: prop.description, type: prop.type };
            return acc;
          }, {}),
          sdfAction: device.sdfAction.reduce((acc, action) => {
            acc[action.name] = {
              description: action.description,
              sdfInputData: action.inputdata ? JSON.parse(action.inputdata) : undefined,
              sdfOutputData: action.outputdata ? JSON.parse(action.outputdata) : undefined
            };
            return acc;
          }, {}),
          sdfEvent: device.sdfEvent.reduce((acc, event) => {
            acc[event.name] = {
              description: event.description,
              sdfOutputData: event.outputdata ? JSON.parse(event.outputdata) : undefined
            };
            return acc;
          }, {})
        }
      }
    };
    console.log(JSON.stringify(deviceDefinition, null, 2));
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        IoT Device Definition
      </Typography>
      <Box display="flex" flexDirection={'column'} justifyContent="space-between" alignItems="flex-start" marginBottom={2}>
        <TextField
          label="Device Name"
          name="name"
          value={device.name}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Device Description"
          name="description"
          value={device.description}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
      </Box>
      <Box display="flex" flexDirection={'column'} justifyContent="space-between" alignItems="flex-start" marginBottom={2}>
        <Typography variant="h6" gutterBottom>
          Properties
        </Typography>
        {device.sdfProperty.map((property, index) => (
          <Paper key={index} style={{ padding: 16, marginBottom: 16 }}>
            <Grid2 container spacing={2}>
              <Grid2 item xs={12} sm={4}>
                <TextField
                  label="Name"
                  name="name"
                  value={property.name}
                  onChange={(e) => handleFieldChange('sdfProperty', index, e)}
                  fullWidth
                />
              </Grid2>
              <Grid2 item xs={12} sm={4}>
                <TextField
                  label="Description"
                  name="description"
                  value={property.description}
                  onChange={(e) => handleFieldChange('sdfProperty', index, e)}
                  fullWidth
                />
              </Grid2>
              <Grid2 item xs={12} sm={4}>
                <Autocomplete
                  options={['number', 'string', 'boolean', 'integer', 'array']}
                  value={property.type}
                  onChange={(e, value) => handleAutocompleteChange('sdfProperty', index, value)}
                  getOptionLabel={(option) => option.charAt(0).toUpperCase() + option.slice(1)}
                  renderInput={(params) => <TextField {...params} label="Type" fullWidth />}
                  renderOption={(props, option) => (
                    <li {...props} style={{ textTransform: 'capitalize' }}>
                      {option}
                    </li>
                  )}
                  style={{ minWidth: 200 }}
                  // fullWidth
                />
              </Grid2>
              <Grid2 item xs={12}>
                <IconButton onClick={() => handleRemoveField('sdfProperty', index)}>
                  <RemoveIcon />
                </IconButton>
              </Grid2>
            </Grid2>
          </Paper>
        ))}
        <Button variant="contained" color="primary" onClick={() => handleAddField('sdfProperty')}>
          Add Property
        </Button>
      </Box>
      <Box display="flex" flexDirection={'column'} justifyContent="space-between" alignItems="flex-start" marginBottom={2}>
        <Typography variant="h6" gutterBottom>
          Actions
        </Typography>
        {device.sdfAction.map((action, index) => (
          <Paper key={index} style={{ padding: 16, marginBottom: 16 }}>
            <Grid2 container spacing={2}>
              <Grid2 item xs={12} sm={4}>
                <TextField
                  label="Name"
                  name="name"
                  value={action.name}
                  onChange={(e) => handleFieldChange('sdfAction', index, e)}
                  fullWidth
                />
              </Grid2>
              <Grid2 item xs={12} sm={4}>
                <TextField
                  label="Description"
                  name="description"
                  value={action.description}
                  onChange={(e) => handleFieldChange('sdfAction', index, e)}
                  fullWidth
                />
              </Grid2>
              <Grid2 item xs={12} sm={4}>
                <TextField
                  label="Input Data"
                  name="inputdata"
                  value={action.inputdata}
                  onChange={(e) => handleFieldChange('sdfAction', index, e)}
                  fullWidth
                />
              </Grid2>
              <Grid2 item xs={12} sm={4}>
                <TextField
                  label="Output Data"
                  name="outputdata"
                  value={action.outputdata}
                  onChange={(e) => handleFieldChange('sdfAction', index, e)}
                  fullWidth
                />
              </Grid2>
              <Grid2 item xs={12}>
                <IconButton onClick={() => handleRemoveField('sdfAction', index)}>
                  <RemoveIcon />
                </IconButton>
              </Grid2>
            </Grid2>
          </Paper>
        ))}
        <Button variant="contained" color="primary" onClick={() => handleAddField('sdfAction')}>
          Add Action
        </Button>
      </Box>
      <Box display="flex" flexDirection={'column'} justifyContent="space-between" alignItems="flex-start" marginBottom={2}>
        <Typography variant="h6" gutterBottom>
          Events
        </Typography>
        {device.sdfEvent.map((event, index) => (
          <Paper key={index} style={{ padding: 16, marginBottom: 16 }}>
            <Grid2 container spacing={2}>
              <Grid2 item xs={12} sm={4}>
                <TextField
                  label="Name"
                  name="name"
                  value={event.name}
                  onChange={(e) => handleFieldChange('sdfEvent', index, e)}
                  fullWidth
                />
              </Grid2>
              <Grid2 item xs={12} sm={4}>
                <TextField
                  label="Description"
                  name="description"
                  value={event.description}
                  onChange={(e) => handleFieldChange('sdfEvent', index, e)}
                  fullWidth
                />
              </Grid2>
              <Grid2 item xs={12} sm={4}>
                <TextField
                  label="Output Data"
                  name="outputdata"
                  value={event.outputdata}
                  onChange={(e) => handleFieldChange('sdfEvent', index, e)}
                  fullWidth
                />
              </Grid2>
              <Grid2 item xs={12}>
                <IconButton onClick={() => handleRemoveField('sdfEvent', index)}>
                  <RemoveIcon />
                </IconButton>
              </Grid2>
            </Grid2>
          </Paper>
        ))}
        <Button variant="contained" color="primary" onClick={() => handleAddField('sdfEvent')}>
          Add Event
        </Button>
      </Box>
      <Box display="flex" flexDirection={'column'} justifyContent="space-between" alignItems="flex-start" marginBottom={2}>
        <Button variant="contained" color="secondary" onClick={handleSubmit} style={{ marginTop: 16 }}>
          Construct Device Definition
        </Button>
      </Box>
    </Container>
  );
};

export default IoTDeviceDefinition;